/*!

 =========================================================
 * Argon Design System Pro - v1.0.3
 =========================================================

 * Product Page: https://www.creative-tim.com/product/argon-design-system-pro
 * Copyright 2020 Creative Tim (http://www.creative-tim.com)

 * Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */


var transparent = true;
var big_image;

var transparentDemo = true;
var fixedTop = false;

var navbar_initialized,
	backgroundOrange = false,
	toggle_initialized = false;

var $datepicker = $('.datepicker');
var $collapse = $('.navbar .collapse');
var $html = $('html');
var $tagsinput = $('.tagsinput');

(function () {
	var isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;

	if (isWindows) {
		// if we are on windows OS we activate the perfectScrollbar function

		$('.wrapper .login-page, .register-page, .card').perfectScrollbar();


		if ($('.tab-content .table-responsive').length != 0) {

			$('.table-responsive').each(function () {
				var ps2 = new PerfectScrollbar($(this)[0]);
			});
		}

		$html.addClass('perfect-scrollbar-on');
	} else {
		$html.addClass('perfect-scrollbar-off');
	}
})();

$(document).ready(function () {

	// Multilevel Dropdown menu

	$('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
		var $el = $(this);
		var $parent = $(this).offsetParent(".dropdown-menu");
		if (!$(this).next().hasClass('show')) {
			$(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
		}
		var $subMenu = $(this).next(".dropdown-menu");
		$subMenu.toggleClass('show');

		$(this).closest("a").toggleClass('open');

		$(this).parents('a.dropdown-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
			$('.dropdown-menu .show').removeClass("show");
		});

		if (!$parent.parent().hasClass('navbar-nav')) {
			$el.next().css({
				"top": $el[0].offsetTop,
				"left": $parent.outerWidth() - 4
			});
		}

		return false;
	});

	//
	// Form control
	//

	'use strict';

	var FormControl = (function () {

		// Variables

		var $input = $('.form-control');


		// Methods

		function init($this) {
			$this.on('focus blur', function (e) {
				$(this).parents('.form-group').toggleClass('focused', (e.type === 'focus'));
			}).trigger('blur');
		}


		// Events

		if ($input.length) {
			init($input);
		}

	})();

	// // Datepicker
	// flatpickr('.flatpickr', {});

	// // Datepicker - range
	// flatpickr('.range', {
	// 	mode: "range"
	// });

	// // DateTimePicker
	// flatpickr('.datetimepicker', {
	// 	enableTime: true,
	// 	dateFormat: "Y-m-d H:i",
	// });

	// Headroom - show/hide navbar on scroll
	if ($('.headroom')[0]) {
		var headroom = new Headroom(document.querySelector("#navbar-main"), {
			offset: 300,
			tolerance: {
				up: 30,
				down: 30
			},
		});
		headroom.init();
	}

	if ($('#choices-single-default')[0]) {
		// Activate Choices
		new Choices('#choices-single-default', {
			search: false,
		});
	}
	if ($('#choices-multiple-default')[0]) {
		new Choices('#choices-multiple-default', {
			search: true,
			delimiter: ',',
			editItems: true,
			removeItemButton: true,
		});
	}

	if ($('#badges')[0]) {
		// Activate Tags
		new Choices('#badges', {
			delimiter: ',',
			editItems: true,
			maxItems: 5,
			removeButton: true,
			removeItemButton: true,
		});
	}

	// Activate Sliders
	ArgonKit.initSliders();

	// Tooptips
	$(function () {
		$('[data-toggle="tooltip"]').tooltip()
	})

	//
	// Popover
	//

	'use strict';

	var Popover = (function () {

		// Variables

		var $popover = $('[data-toggle="popover"]'),
			$popoverClass = '';


		// Methods

		function init($this) {
			if ($this.data('color')) {
				$popoverClass = 'popover-' + $this.data('color');
			}

			var options = {
				trigger: 'focus',
				template: '<div class="popover ' + $popoverClass + '" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
			};

			$this.popover(options);
		}


		// Events

		if ($popover.length) {
			$popover.each(function () {
				init($(this));
			});
		}


	})();

	// Back to top button

	var btn = $('.back-to-top');

	$(window).scroll(function () {
		if ($(window).scrollTop() > 300) {
			btn.addClass('show');
		} else {
			btn.removeClass('show');
		}
	});

	btn.on('click', function (e) {
		e.preventDefault();
		$('html, body').animate({
			scrollTop: 0
		}, '300');
	});

});

$(document).on('click', '.card-rotate .btn-rotate', function () {
	var $rotating_card_container = $(this).closest('.rotating-card-container');

	if ($rotating_card_container.hasClass('hover')) {
		$rotating_card_container.removeClass('hover');
	} else {
		$rotating_card_container.addClass('hover');
	}
});


// Add (+/-) Button Number Incrementers

function up(max) {
	document.getElementById("myNumber").value = parseInt(document.getElementById("myNumber").value) + 1;
	if (document.getElementById("myNumber").value >= parseInt(max)) {
		document.getElementById("myNumber").value = max;
	}
}

function down(min) {
	document.getElementById("myNumber").value = parseInt(document.getElementById("myNumber").value) - 1;
	if (document.getElementById("myNumber").value <= parseInt(min)) {
		document.getElementById("myNumber").value = min;
	}
}

// $(window).on('resize', function(){
//     ArgonKit.initNavbarImage();
// });

var didScroll;

ArgonKit = {
	misc: {
		navbar_menu_visible: 0
	},

	checkScrollForTransparentNavbar: debounce(function () {
		if ($(document).scrollTop() > scroll_distance) {
			if (transparent) {
				transparent = false;
				$('.navbar[color-on-scroll]').removeClass('navbar-transparent');
			}
		} else {
			if (!transparent) {
				transparent = true;
				$('.navbar[color-on-scroll]').addClass('navbar-transparent');
			}
		}
	}, 17),


	initSliders: function () {
		// Sliders for demo purpose in refine cards section
		var slider = document.getElementById('sliderRegular');

		if (slider) {
			noUiSlider.create(slider, {
				start: 40,
				connect: [true, false],
				range: {
					min: 0,
					max: 100
				}
			});
		}

		var slider2 = document.getElementById('sliderDouble');

		if (slider) {
			noUiSlider.create(slider2, {
				start: [20, 60],
				connect: true,
				range: {
					min: 0,
					max: 100
				}
			});
		}
	},

	initGoogleMaps: function () {
		var myLatlng = new google.maps.LatLng(40.748817, -73.985428);
		var mapOptions = {
			zoom: 13,
			center: myLatlng,
			scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
			styles: [{
				"featureType": "administrative",
				"elementType": "labels.text.fill",
				"stylers": [{
					"color": "#444444"
				}]
			},
			{
				"featureType": "landscape",
				"elementType": "all",
				"stylers": [{
					"color": "#f2f2f2"
				}]
			},
			{
				"featureType": "poi",
				"elementType": "all",
				"stylers": [{
					"visibility": "off"
				}]
			},
			{
				"featureType": "road",
				"elementType": "all",
				"stylers": [{
					"saturation": -100
				}, {
					"lightness": 45
				}]
			},
			{
				"featureType": "road.highway",
				"elementType": "all",
				"stylers": [{
					"visibility": "simplified"
				}]
			},
			{
				"featureType": "road.arterial",
				"elementType": "labels.icon",
				"stylers": [{
					"visibility": "off"
				}]
			},
			{
				"featureType": "transit",
				"elementType": "all",
				"stylers": [{
					"visibility": "off"
				}]
			},
			{
				"featureType": "water",
				"elementType": "all",
				"stylers": [{
					"color": "#C5CBF5"
				}, {
					"visibility": "on"
				}]
			}
			]
		};

		var map = new google.maps.Map(document.getElementById("map-contactus-1"), mapOptions);

		var marker = new google.maps.Marker({
			position: myLatlng,
			title: "Hello World!"
		});

		// To add the marker to the map, call setMap();
		marker.setMap(map);
	},

	initGoogleMaps2: function () {
		var myLatlng = new google.maps.LatLng(40.748817, -73.985428);
		var mapOptions = {
			zoom: 13,
			center: myLatlng,
			scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
			styles: [{
				"featureType": "administrative",
				"elementType": "labels.text.fill",
				"stylers": [{
					"color": "#444444"
				}]
			},
			{
				"featureType": "landscape",
				"elementType": "all",
				"stylers": [{
					"color": "#f2f2f2"
				}]
			},
			{
				"featureType": "poi",
				"elementType": "all",
				"stylers": [{
					"visibility": "off"
				}]
			},
			{
				"featureType": "road",
				"elementType": "all",
				"stylers": [{
					"saturation": -100
				}, {
					"lightness": 45
				}]
			},
			{
				"featureType": "road.highway",
				"elementType": "all",
				"stylers": [{
					"visibility": "simplified"
				}]
			},
			{
				"featureType": "road.arterial",
				"elementType": "labels.icon",
				"stylers": [{
					"visibility": "off"
				}]
			},
			{
				"featureType": "transit",
				"elementType": "all",
				"stylers": [{
					"visibility": "off"
				}]
			},
			{
				"featureType": "water",
				"elementType": "all",
				"stylers": [{
					"color": "#C5CBF5"
				}, {
					"visibility": "on"
				}]
			}
			]
		};

		var map = new google.maps.Map(document.getElementById("map-contactus-2"), mapOptions);

		var marker = new google.maps.Marker({
			position: myLatlng,
			title: "Hello World!"
		});

		// To add the marker to the map, call setMap();
		marker.setMap(map);
	},

	initGoogleMaps3: function () {
		var myLatlng = new google.maps.LatLng(40.748817, -73.985428);
		var mapOptions = {
			zoom: 13,
			center: myLatlng,
			scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
			styles: [{
				"featureType": "administrative",
				"elementType": "labels.text.fill",
				"stylers": [{
					"color": "#444444"
				}]
			},
			{
				"featureType": "landscape",
				"elementType": "all",
				"stylers": [{
					"color": "#f2f2f2"
				}]
			},
			{
				"featureType": "poi",
				"elementType": "all",
				"stylers": [{
					"visibility": "off"
				}]
			},
			{
				"featureType": "road",
				"elementType": "all",
				"stylers": [{
					"saturation": -100
				}, {
					"lightness": 45
				}]
			},
			{
				"featureType": "road.highway",
				"elementType": "all",
				"stylers": [{
					"visibility": "simplified"
				}]
			},
			{
				"featureType": "road.arterial",
				"elementType": "labels.icon",
				"stylers": [{
					"visibility": "off"
				}]
			},
			{
				"featureType": "transit",
				"elementType": "all",
				"stylers": [{
					"visibility": "off"
				}]
			},
			{
				"featureType": "water",
				"elementType": "all",
				"stylers": [{
					"color": "#C5CBF5"
				}, {
					"visibility": "on"
				}]
			}
			]
		};

		var map = new google.maps.Map(document.getElementById("map-contactus-3"), mapOptions);

		var marker = new google.maps.Marker({
			position: myLatlng,
			title: "Hello World!"
		});

		// To add the marker to the map, call setMap();
		marker.setMap(map);
	}
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.

function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		clearTimeout(timeout);
		timeout = setTimeout(function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		}, wait);
		if (immediate && !timeout) func.apply(context, args);
	};
};